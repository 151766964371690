import {
  SWIPEABLE_CONTENT_CHANGED,
  SHOW_HIDDEN_TEASER,
  triggerEvent,
} from '@fec/assets/js/utils/event';
import { STORAGE_KEY } from '@fec/frontend/elements/text/teaser-info/teaser-info-updater';
import ClientStorage from '@fec/frontend/foundation/client/storage';
import { DOM_CHANGED_EVENT } from '@fec/assets/js/utils/event';
import { readUrnFromMetaTag } from '@fec/assets/js/utils/meta-tags';

/**
 * Articles contain an initially hidden swipeable collection after the content.
 *
 * This function
 * - reads an endpoint's URL from the DOM element
 * - loads article teasers from that endpoint
 * - removes a teaser for the currently viewed article, if it exists
 * - initializes and shows the collection
 * - triggers the lazyloading mechanism
 */
export function init() {
  let currentArticleURN = readUrnFromMetaTag();

  let $collection = $('.js-autobox-collection');
  let collection = document.querySelector('.js-autobox-collection');
  if (!$collection.length) {
    return;
  }

  let $teaserContainer = $collection.find(
    '.js-collection-ng-swipeable-container',
  );
  let cmsEndpoint = $collection.data(`load-url-default`);

  $teaserContainer.load(cmsEndpoint, () => {
    let $teaserMains = $collection.find('.js-teaser-ng');

    // no teasers = no related articles will be shown
    if (!$teaserMains.length) {
      $collection.remove();
      return;
    }

    // get URNs of all articles that have been seen by this user already
    const seenArticleURNs = ClientStorage.getItemJsonParsed(
      STORAGE_KEY,
      [],
    ).map((item) => item.urn);

    $teaserMains.each((index, element) => {
      const urn = $(element).data('urn');

      // remove teaser (+ wrapper) with the same URN as the article we're on
      if (urn === currentArticleURN) {
        $(element).parents('.js-teaser-ng-item').remove();
        return;
      }
      // move seen items to the end.
      if (seenArticleURNs.includes(urn)) {
        $(element)
          .closest('.js-collection-ng-swipeable-container')
          .append($(element).closest('.js-teaser-ng-item'));
      }
    });

    // edge case: the only teaser was of the current article --> no related articles
    if ($collection.find('.js-teaser-ng-item').length === 0) {
      $collection.remove();
      return;
    }

    // hard limit to 6 items:
    $collection.find('.js-teaser-ng-item').slice(6).remove();

    // show collection …
    collection.classList.remove('collection-ng--hidden');

    // … and let it know that there's new content
    triggerEvent(SWIPEABLE_CONTENT_CHANGED, null, $collection[0]);
    $collection.find('.js-teaser-ng').each((_, teaserElement) => {
      triggerEvent(SHOW_HIDDEN_TEASER, teaserElement);
    });

    triggerEvent(DOM_CHANGED_EVENT);
  });
}
