import {
  readCommentsApiEndpoint,
  readUrnFromMetaTag,
} from '@fec/assets/js/utils/meta-tags';
import { PublicThreadsService, OpenAPI } from '@Comments/openapi/client';
import { triggerEvent } from '@fec/assets/js/utils/event';
import { COMMENTS_API_STATS } from './classes/SrfCommentsEvents';
import Urn from '@fec/frontend/foundation/client/urn';

function getThreadStats(threadUrn) {
  OpenAPI.BASE = readCommentsApiEndpoint();
  OpenAPI.TOKEN = undefined;

  return PublicThreadsService.getApiPublicThreadStats({
    bu: 'srf',
    urn: threadUrn,
  });
}

export async function init() {
  try {
    const threadUrn = readUrnFromMetaTag();
    if (Urn.getType(threadUrn) !== 'article') {
      return;
    }

    const result = await getThreadStats(threadUrn);
    if (result && typeof result === 'object') {
      triggerEvent(COMMENTS_API_STATS, result);
      // only load comments if the thread is open or has comments - otherwise we don't need to load anything
      if (result.threadState === 'open' || result.commentsPublished > 0) {
        const janrainModule = await import(
          /* webpackChunkName: 'srf-comments-login' */ './srf-janrain.js'
        );

        if (janrainModule) {
          janrainModule.init();

          const srfCommentsModule = await import(
            /* webpackChunkName: 'srf-comments-react' */ '../../comments/index.tsx'
          );

          if (srfCommentsModule) {
            srfCommentsModule.init(result.commentsPublished);
          }
        }
      }
    }
  } catch (error) {
    // thread doesn't have comments or stats request failed
  }
}
