import Cookies from 'js-cookie';
import ClientStorage from '@fec/frontend/foundation/client/storage';
import { readUrnFromMetaTag } from '@fec/assets/js/utils/meta-tags';

const COOKIE_NAME = 'qa';
const QA_ENDPOINT = '/qa/feedback';
const LOCALSTORAGE_QA_EMAIL_KEY = 'srf.qa.email';

export function init() {
  if (typeof Cookies.get(COOKIE_NAME) === 'undefined') {
    return;
  }

  let secret = Cookies.get(COOKIE_NAME);

  // add button to show form including click-event-handler
  let $qaButton = $(`
    <button class="qa-button button button--circle button--fixed-bottom-right js-submit-error">
      <div class="button-wrapper">
        <span class="button-icon"></span>
        <span class="button-text">Feedback geben</span>
      </div>
    </button>
  `);
  $('body').append($qaButton);

  let $qaWrapper = $('<div class="qa-wrapper"></div>');
  $('body').append($qaWrapper);

  let removeForm = () => {
    // empty & hide form
    $qaWrapper.html('');
    $qaWrapper.hide();
  };

  let addFormToPage = (result) => {
    let $form = $(result);
    $qaWrapper.append($form);

    // write current url to hidden field
    $qaWrapper.find('#feedback_url').val(window.location.href);

    // write article URN to hidden field
    $qaWrapper.find('#feedback_urn').val(readUrnFromMetaTag());

    // write secret in the other hidden field
    $qaWrapper.find('#feedback_secret').val(secret);
    $qaWrapper.find('.js-abort-button').click(removeForm);
    let $formBtn = $qaWrapper.find('.js-submit-button');

    // write email address if available
    if (ClientStorage.hasItem(LOCALSTORAGE_QA_EMAIL_KEY)) {
      $qaWrapper
        .find('#feedback_mail')
        .val(ClientStorage.getItem(LOCALSTORAGE_QA_EMAIL_KEY));
    }

    $qaWrapper.find('label').addClass('input-field');
    $qaWrapper
      .find('#feedback_message, #feedback_mail')
      .addClass('input-field__text');

    $form.submit((e) => {
      e.preventDefault();

      $formBtn.addClass('button--pending');

      $.ajax({
        url: QA_ENDPOINT,
        method: 'POST',
        data: $('.qa-wrapper form').serialize(),
        success: (response) => {
          $formBtn.addClass('button--success');
          ClientStorage.setItem(
            LOCALSTORAGE_QA_EMAIL_KEY,
            $qaWrapper.find('#feedback_mail').val(),
          );
          setTimeout(() => removeForm(response), 800);
        },
        error: () => $formBtn.addClass('button--on-error'),
        complete: () => $formBtn.removeClass('button--pending'),
      });
    });

    $qaWrapper.show();
  };

  $qaButton.on('click', () => {
    $qaButton.addClass('button--pending');

    $.ajax({
      url: QA_ENDPOINT,
      success: (result) => {
        addFormToPage(result);
      },
      complete: () => $qaButton.removeClass('button--pending'),
      dataType: 'html',
    });
  });
}
