import moment from 'moment';

import { Trans } from '@fec/assets/js/utils/trans';

export function init() {
  const trans = new Trans();

  moment.locale('datetime', {
    calendar: {
      sameDay: 'YYYY-MM-DD HH:mm',
      lastDay: 'YYYY-MM-DD HH:mm',
      lastWeek: 'YYYY-MM-DD HH:mm',
      sameElse: 'YYYY-MM-DD HH:mm',
    },
  });

  // Romansh
  moment.locale('rm-pub', {
    weekdays: trans.getWeekDays(),
    months: trans.getMonths(),
    calendar: {
      sameDay: '[Oz,] HH:mm',
      lastDay: '[Ier,] HH:mm',
      lastWeek: 'dddd, D.M.YYYY, HH:mm',
      sameElse: 'dddd, D.M.YYYY, HH:mm',
    },
  });

  moment.locale('rm-mod', {
    weekdays: trans.getWeekDays(),
    calendar: {
      sameDay: '[Actualisaziun: Oz,] HH:mm',
      lastDay: '[Actualisaziun: Ier,] HH:mm',
      lastWeek: '[Actualisaziun:] dddd, D.M.YYYY, HH:mm',
      sameElse: '[Actualisaziun:] dddd, D.M.YYYY, HH:mm',
    },
  });

  moment.locale('rm-mod-short', {
    calendar: {
      sameDay: '[Actualisà a las] HH:mm',
      lastDay: '[Actualisà a las] HH:mm',
      lastWeek: '[Actualisà a las] HH:mm',
      sameElse: '[Actualisà a las] HH:mm',
    },
  });

  // German
  moment.locale('de-pub', {
    weekdays: trans.getWeekDays(),
    months: trans.getMonths(),
    calendar: {
      sameDay: '[Heute,] HH:mm [Uhr]',
      lastDay: '[Gestern,] HH:mm [Uhr]',
      lastWeek: 'dddd, DD.MM.YYYY, HH:mm [Uhr]',
      sameElse: 'dddd, DD.MM.YYYY, HH:mm [Uhr]',
    },
  });

  moment.locale('de-mod', {
    weekdays: trans.getWeekDays(),
    months: trans.getMonths(),
    calendar: {
      sameDay: '[Aktualisiert Heute,] HH:mm [Uhr]',
      lastDay: '[Aktualisiert Gestern,] HH:mm [Uhr]',
      lastWeek: '[Aktualisiert am] dddd, DD.MM.YYYY, HH:mm [Uhr]',
      sameElse: '[Aktualisiert am] dddd, DD.MM.YYYY, HH:mm [Uhr]',
    },
  });

  moment.locale('de-mod-short', {
    calendar: {
      sameDay: '[Aktualisiert um] HH:mm [Uhr]',
      lastDay: '[Aktualisiert um] HH:mm [Uhr]',
      lastWeek: '[Aktualisiert um] HH:mm [Uhr]',
      sameElse: '[Aktualisiert um] HH:mm [Uhr]',
    },
  });

  /**
   * Depends on business unit to determine right moment js formatting
   *
   * @param customSuffix
   * @returns {string}
   */
  let getMomentLocale = function (customSuffix) {
    const businessUnit = document.body?.dataset?.bu ?? 'srf';
    return businessUnit === 'srf'
      ? 'de-' + customSuffix
      : businessUnit === 'rtr'
        ? 'rm-' + customSuffix
        : '';
  };

  /**
   * @param date
   * @param formatType
   * @returns {string}
   */
  let createTimeTagElement = function (date, formatType) {
    const css = formatType === 'mod-short' ? 'article-author__date-update' : '';
    const dateTime = moment(date).locale('datetime').calendar();
    const text = moment(date).locale(getMomentLocale(formatType)).calendar();

    return `<time class="${css}" datetime="${dateTime}">${text}</time>`;
  };

  /**
   * Creates html time tags depending on given dates.
   * If an article was modified the same day it was published, both dates are shown.
   * If an article was modified after the publication date, only the modification date is shown.
   *
   * @param publicationDate
   * @param modificationDate
   * @returns string
   */
  let getDateLine = function (publicationDate, modificationDate) {
    if (
      modificationDate.toDateString() === publicationDate.toDateString() &&
      modificationDate.getTime() !== publicationDate.getTime()
    ) {
      // show both
      return (
        createTimeTagElement(publicationDate, 'pub') +
        createTimeTagElement(modificationDate, 'mod-short')
      );
    } else if (modificationDate.getTime() > publicationDate.getTime()) {
      // show only modificationDate
      return createTimeTagElement(modificationDate, 'mod');
    } else {
      // show only publicationDate
      return createTimeTagElement(publicationDate, 'pub');
    }
  };

  document.querySelectorAll('.js-dateline').forEach((e) => {
    const publicationDate = new Date(e.dataset.publicationdate);
    const modificationDate = new Date(e.dataset.modificationdate);
    e.innerHTML = getDateLine(publicationDate, modificationDate);
  });
}
