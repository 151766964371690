/**
 * This event is triggered when the client application issues a logout directive
 *
 * @type {string}
 */
export const COMMENTS_TRIGGER_LOGOUT = `commentsFrontend:logout`;

/**
 * This event is triggered when the client application issues a login directive
 *
 * @type {string}
 */
export const COMMENTS_TRIGGER_OPEN_LOGIN_SCREEN = `commentsFrontend:commentSubmit`;

/**
 * This event is triggered when the client application issues a user profile directive
 *
 * @type {string}
 */
export const COMMENTS_TRIGGER_EDIT_USER_PROFILE = `commentsFrontend:editUserProfile`;

/**
 * This event asks the login provider to check if a user session is present and trigger a login
 *
 * @type {string}
 */
export const COMMENTS_TRIGGER_FIND_SESSION = `commentsFrontend:findSession`;

/**
 * This event is triggered when the login provider populates a successfully logged in user
 *
 * @type {string}
 */
export const COMMENTS_LOGGED_IN = `srfJanrain:loggedIn`;

/**
 * This event is triggered when the result of the comments api stats endpoint is available
 *
 * @type {string}
 */
export const COMMENTS_API_STATS = 'commentsApi:stats';
