import { TRACK_INTERACTION, triggerEvent } from '@fec/assets/js/utils/event';
import { readUrnFromMetaTag } from '@fec/assets/js/utils/meta-tags';
import Urn from '@fec/frontend/foundation/client/urn';

export function init() {
  const urn = readUrnFromMetaTag();
  if (!['article', 'landingpage'].includes(Urn.getType(urn))) {
    return;
  }
  window.setTimeout(() => {
    sendTrackingEvt('quality_visit');
  }, 15000);

  if (window.IntersectionObserver) {
    startObserving();
  }
}

function startObserving() {
  const observedElement = document.querySelector('[itemprop="publisher"]');
  let observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer.unobserve(entry.target);
          sendTrackingEvt('reach_bottom');
        }
      });
    },
    { threshold: 1 },
  );

  if (observedElement) {
    observer.observe(observedElement);
  }
}

function sendTrackingEvt(evt_name) {
  triggerEvent(TRACK_INTERACTION, {
    event_source: 'article',
    event_name: evt_name,
    event_type: 'hidden_event',
  });
}
